import React from 'react'
import classnames from 'classnames'
import { ArrowRight } from '../../ui/Icon'
import { InternalLink } from '../../ui/Link'
import {
	bgMain,
	bgMain2,
	bgMain3,
	bgMain4,
	companyLink,
	companyLinkContainer,
	container,
	ctaContent,
	overlay,
} from './Cta.module.scss'

export const Cta = () => {
	return (
		<div className={classnames(container, 'relative w-full')} id="cta">
			<div className={classnames(bgMain, 'absolute top-0 left-0 z-10 w-full bg-center bg-no-repeat bg-cover')} />
			<div className={classnames(bgMain2, 'absolute top-0 left-0 z-10 w-full bg-center bg-no-repeat bg-cover')} />
			<div className={classnames(bgMain3, 'absolute top-0 left-0 z-10 w-full bg-center bg-no-repeat bg-cover')} />
			<div className={classnames(bgMain4, 'absolute top-0 left-0 z-10 w-full bg-center bg-no-repeat bg-cover')} />
			<div className={classnames(overlay, 'absolute top-0 left-0 w-full bg-center bg-no-repeat bg-cover')} />
			<div className={classnames(ctaContent, 'absolute z-20')}>
				<h1 className="text-3xl lg:text-5xl text-secondary">外食に</h1>
				<h1 className="mt-1 lg:mt-4 text-3xl lg:text-5xl text-secondary">テクノロジーを</h1>
				<h2 className="mt-1 lg:mt-4 text-base lg:text-2xl text-secondary">Empower all the restaurants.</h2>
			</div>
			<div className={companyLinkContainer}>
				<InternalLink href="/company" className={companyLink}>
					<span className="z-30">会社情報を見る</span>
					<ArrowRight />
				</InternalLink>
			</div>
		</div>
	)
}
