import React, { useEffect, useRef, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import classnames from 'classnames'

import { A11y, Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import {
	categoryTxt,
	eyeCatchBgFdm,
	eyeCatchBgLisa,
	newsImage,
	swiperContainer,
	swiperSlide,
} from './NewsSlide.module.scss'

const EyeCatchImageBg: React.FC<{ category: string }> = ({ category }) => {
	return (
		<div
			className={classnames(
				category === 'FDMリリース' ? eyeCatchBgFdm : eyeCatchBgLisa,
				'bg-center bg-no-repeat bg-contain rounded-l border'
			)}
		/>
	)
}

export const NewsSlide = () => {
	const data = useStaticQuery(graphql`
		query MyQuery {
			allWpPost(limit: 7, sort: { fields: date, order: DESC }) {
				nodes {
					excerpt
					title
					id
					date(formatString: "YYYY.MM.DD")
					featuredImage {
						node {
							id
							sizes
							sourceUrl
							srcSet
							caption
							localFile {
								id
								childImageSharp {
									id
									gatsbyImageData(layout: CONSTRAINED, height: 168, quality: 100)
								}
							}
						}
					}
					categories {
						nodes {
							id
							name
							slug
						}
					}
				}
			}
		}
	`)

	const [mount, setMount] = useState(false)
	const prevRef = useRef<HTMLDivElement>(null)
	const nextRef = useRef<HTMLDivElement>(null)
	const paginationRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setMount(true)
	}, [])

	return (
		<>
			<Swiper
				className={swiperContainer}
				modules={[Navigation, Pagination, Scrollbar, A11y]}
				breakpoints={{
					'320': {
						slidesPerView: 1,
						spaceBetween: 10,
						freeMode: true,
					},
					'640': {
						slidesPerView: 3,
						spaceBetween: 30,
					},
				}}
				navigation={{
					prevEl: prevRef.current!, // Assert non-null
					nextEl: nextRef.current!, // Assert non-null
				}}
				pagination={{ el: paginationRef.current!, clickable: true }}
				onInit={(swiper) => {
					if (mount) {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line no-param-reassign
						swiper.params.navigation.prevEl = prevRef.current
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line no-param-reassign
						swiper.params.navigation.nextEl = nextRef.current
						swiper.navigation.update()
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line no-param-reassign
						swiper.params.pagination.el = nextRef.current
						swiper.pagination.update()
					}
				}}
			>
				{data
					? data.allWpPost.nodes.map((post) => {
							const category = post.categories.nodes[0].name
							const image =
								post.featuredImage !== null && post.featuredImage.node.localFile.childImageSharp.gatsbyImageData

							return (
								<SwiperSlide key={post.id} className={swiperSlide}>
									<Link to={`/news/${post.id}`} className="flex flex-col hover:opacity-70 duration-300">
										{post.featuredImage === null ? (
											<EyeCatchImageBg category={category} />
										) : (
											<GatsbyImage
												image={image}
												alt={`${post.title}のアイキャッチ画像`}
												className={classnames(newsImage, 'object-contain rounded-l')}
												objectFit="contain"
												objectPosition="center"
											/>
										)}
										<div className="flex justify-start items-center mt-5">
											<span
												className={classnames(
													categoryTxt,
													'py-1 px-2 w-24 text-center text-secondary bg-gray rounded-big'
												)}
											>
												{category}
											</span>
											<small className="ml-4 text-xs">{post.date}</small>
										</div>
										<h3 className="overflow-hidden mt-5 text-sm font-bold whitespace-pre-wrap">
											{post.title.substr(0, 60)}
											{post.title.length > 60 ? '...' : null}
										</h3>
									</Link>
								</SwiperSlide>
							)
					  })
					: null}
			</Swiper>
			<div ref={paginationRef} className="swiper-pagination-news" />
			<div ref={prevRef} className="hidden md:block swiper-button-prev-news" />
			<div ref={nextRef} className="hidden md:block swiper-button-next-news" />
		</>
	)
}
