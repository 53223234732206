import React from 'react'
import classnames from 'classnames'
import { container, newsLink } from './News.module.scss'
import { ArrowRight } from '../../ui/Icon'
import { InternalLink } from '../../ui/Link'
import { Title } from '../../ui/Title'
import { NewsSlide } from '../../../templates/NewsSlide'

export const News = () => {
	return (
		<div className={classnames(container, 'flex flex-col')}>
			<div className="flex justify-between items-end">
				<Title title="ニュース" titleSub="NEWS" />
				<InternalLink href="/news" className={newsLink}>
					<span className="z-30">一覧を見る</span>
					<ArrowRight />
				</InternalLink>
			</div>
			<div className="relative mt-10 lg:mt-14 w-full">
				<NewsSlide />
			</div>
		</div>
	)
}
