import React from 'react'
import classnames from 'classnames'
import { bgCeo, bgMedia, container, mediaBgFlame, mediaIcon, newsMediaLink } from './NewsMedia.module.scss'
import { ExternalLink } from '../../ui/Link'

const newsMedia = [
	{
		type: 'note',
		color: '#41c9b4',
		title: '社長ノート',
		description: '代表のブログです。事業や組織運営にまつわる話を発信しています。',
		link: 'https://note.com/tatupon/',
		className: bgCeo,
	},
	{
		type: 'WEBメディア',
		color: '#f0b200',
		title: 'Food Media',
		description: '飲食業界の最新ニュースや業界動向をお届けするWEBメディアです。',
		link: 'https://foodmedia.jp/',
		className: bgMedia,
	},
]

export const NewsMedia = () => {
	return (
		<div className={classnames(container, 'flex flex-col lg:flex-row justify-between')}>
			{newsMedia.map((media) => {
				return (
					<ExternalLink
						key={media.title}
						href={media.link}
						className={classnames(newsMediaLink, 'flex p-4 rounded border')}
					>
						<div>
							<div style={{ background: media.color }} className="inline-flex justify-center rounded-3xl">
								{media.type === 'note' ? (
									<div className={classnames(mediaIcon, 'bg-center bg-no-repeat bg-cover rounded-3xl')} />
								) : (
									<span className="py-1 px-2 text-xs text-secondary">{media.type}</span>
								)}
							</div>
							<h3 className="mt-3 lg:mt-4 text-sm font-bold">{media.title}</h3>
							<p className="mt-2 lg:mt-4 text-xs">{media.description}</p>
						</div>
						<div className={mediaBgFlame}>
							<div className={classnames(media.className, 'bg-center bg-no-repeat bg-cover')} />
						</div>
					</ExternalLink>
				)
			})}
		</div>
	)
}
