import React from 'react'
import classnames from 'classnames'
import {
	bgServiceFdm,
	bgServiceHuriuri,
	bgServiceTitleFdm,
	bgServiceTitleHuriuri,
	companyLink,
	container,
	serviceIntro,
} from './Service.module.scss'
import { ExternalLinkFluffy } from '../../ui/Link'
import { Title } from '../../ui/Title'

const SERVICE_SECTION = [
	{
		logo: bgServiceTitleFdm,
		image: bgServiceFdm,
		link: 'https://fooddeliverymanager.jp/',
		description: `フードデリバリーマネージャーは、飲食店がデリバリーを安心して運用できるよう様々なサポートを行います。\n複数のデリバリープラットフォームやアカウントデータをクラウドで一括管理、課題を独自システムで分析・可視化、販売戦略にいたる様々な施策を提案し、解決を目指すサービスです。`,
	},
	{
		logo: bgServiceTitleHuriuri,
		image: bgServiceHuriuri,
		link: 'https://huriuri.com/',
		description: `huriuri（フリウリ）は、フードデリバリーに特化したフランチャイズ比較サイトです。\nUber Eatsや出前館で人気のFCブランドに加盟を検討している方とFC本部のマッチングを支援します。どなたでも ”カンタン” にブランドを見つけ、加盟店募集を可能にするサービスです。`,
	},
]

export const Service = () => {
	return (
		<div className={classnames(container, 'flex flex-col')}>
			<Title title="サービス" titleSub="SERVICE" />
			{SERVICE_SECTION.map((service, i) => {
				return (
					<div key={service.link} className={`flex flex-col-reverse lg:flex-row ${i === 0 ? 'mt-10' : 'mt-20'}`}>
						<div className={classnames(serviceIntro, 'flex flex-col mt-8 lg:mt-0')}>
							<div className={classnames(service.logo, 'bg-center bg-no-repeat bg-cover')} />
							<p className="mt-8 text-sm lg:text-base leading-6 whitespace-pre-wrap">{service.description}</p>
							<div className="inline-flex mt-10">
								<ExternalLinkFluffy href={service.link} className={companyLink}>
									サービスサイトを見る
								</ExternalLinkFluffy>
							</div>
						</div>
						<div className={classnames(service.image, 'bg-center bg-no-repeat bg-cover')}></div>
					</div>
				)
			})}
		</div>
	)
}
