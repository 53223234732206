import React from 'react'
import classnames from 'classnames'
import { bgRecruit, container, inner, recruitLink } from './Recruit.module.scss'
import { ExternalLinkFluffy } from '../../ui/Link'
import { Title } from '../../ui/Title'

export const Recruit = () => {
	return (
		<div className={classnames(container, 'lg:flex relative w-full bg-center bg-no-repeat bg-cover')}>
			<div className={classnames(bgRecruit, 'bg-center bg-no-repeat bg-cover')} />
			<div className={classnames(inner, 'absolute')}>
				<div className="lg:ml-auto lg:w-min">
					<div className="p-8 lg:p-10 bg-secondary">
						<Title title="採用情報" titleSub="RECRUIT" />
						<h1 className="mt-8 text-2xl lg:text-4xl font-bold">私たちと一緒に</h1>
						<h1 className="mt-2 text-2xl lg:text-4xl font-bold">外食の課題解決を</h1>
						<h1 className="mt-2 text-2xl lg:text-4xl font-bold">していきませんか？</h1>
						<div className="flex flex-col lg:flex-row mt-7 lg:mt-10">
							<div className="flex flex-col justify-between">
								<ExternalLinkFluffy
									href="https://hyper-sunshine-6c1.notion.site/Lisa-Technologies-Job-Board-f58a33f35de9473bb70c0fd49cdafd8e"
									className={recruitLink}
								>
									エンジニア募集
								</ExternalLinkFluffy>
								<ExternalLinkFluffy
									href="https://hyper-sunshine-6c1.notion.site/Lisa-Technologies-Job-Board-f58a33f35de9473bb70c0fd49cdafd8e"
									className={classnames(recruitLink, 'mt-5')}
								>
									セールス募集
								</ExternalLinkFluffy>
							</div>
							<div className="flex flex-col justify-between mt-5 lg:mt-0 lg:ml-4">
								<ExternalLinkFluffy
									href="https://hyper-sunshine-6c1.notion.site/Lisa-Technologies-Job-Board-f58a33f35de9473bb70c0fd49cdafd8e"
									className={recruitLink}
								>
									コーポレート募集
								</ExternalLinkFluffy>
								<ExternalLinkFluffy
									href="https://hyper-sunshine-6c1.notion.site/Lisa-Technologies-Job-Board-f58a33f35de9473bb70c0fd49cdafd8e"
									className={classnames(recruitLink, 'mt-5')}
								>
									インターン募集
								</ExternalLinkFluffy>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
