import * as React from 'react'
import { Layout } from '../components/common/Layout'
import { SEO } from '../components/common/SEO'
import { Cta, News, NewsMedia, Recruit, Service } from '../components/domains/home'

const IndexPage = () => {
	return (
		<Layout location={[]}>
			<SEO title="LisaTechnologies株式会社" description="" top={true} />
			<Cta />
			<Service />
			<Recruit />
			<News />
			<NewsMedia />
		</Layout>
	)
}

export default IndexPage
